<template>
	<div class="app-container">
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div style="display: flex;align-items: center;">
				<div class="filter-item">
					<label class="label">用户ID: </label>
					<el-input v-model="searchData.userId" clearable style='width:250px' placeholder="请输入用户ID">
					</el-input>
				</div>
				<!-- 时间筛选 -->
				<div class="filter-item">
					<label class="label" style="margin-left: 10px;">提交时间:</label>
					<el-date-picker v-model="applyTime" type="datetimerange" range-separator="～"
						start-placeholder="开始日期" end-placeholder="结束日期" align="right"
						value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</div>
				<div class="filter-item">
					<label class="label" style="margin-left: 20px;">状态:</label>
					<el-select v-model="searchData.auditStatus" placeholder="请选择" filterable clearable
						style="width: 160px;margin-right: 10px;">
						<el-option v-for="(item,index) in activityStateList" :key="index" :label="item.str"
							:value="item.state"></el-option>
					</el-select>
				</div>
			</div>

			<div style="display: flex;align-items: center;">
				<div class="filter-item" style="margin-top: 10px;">
					<label class="label">用户账号: </label>
					<el-input v-model="searchData.phoneNumber" clearable style='width:250px' placeholder="请输入用户账号">
					</el-input>
				</div>
				<div class="filter-item" style="margin-top: 10px;">
					<label class="label">用户昵称: </label>
					<el-input v-model="searchData.nickName" clearable style='width:250px' placeholder="请输入用户昵称">
					</el-input>
				</div>
				<div class="filter-item" style="margin-top: 10px;">
					<label class="label" style="margin-left: 20px;">等级:</label>
					<el-select v-model="searchData.pusherLevel" placeholder="请选择" filterable clearable
						style="width: 160px;margin-right: 10px;">
						<el-option v-for="(item,index) in levelList" :key="index" :label="item.str"
							:value="item.state"></el-option>
					</el-select>
				</div>
				<el-button type="primary" style="width:100px;margin-right:20px;" @click="search()">搜索</el-button>
			</div>

		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border>
				<el-table-column prop="userId" label="用户ID"></el-table-column>
				<el-table-column prop="phoneNumber" label="用户账号"></el-table-column>
				<el-table-column label="等级">
					<template slot-scope="scope">
						<div>{{levelList[scope.row.pusherLevel+1].str}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="nickName" label="用户昵称"></el-table-column>
				<el-table-column label="内容" width="400px">
					<template slot-scope="scope">
						<div style="overflow:hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;padding:0 10px;">{{scope.row.content}}</div>
					</template>
				</el-table-column>
				<el-table-column label="审核说明" width="250px">
					<template slot-scope="scope">
						<div style="overflow:hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;padding:0 10px;">{{scope.row.auditRemark}}</div>
					</template>
				</el-table-column>
				<el-table-column label="提交时间">
					<template slot-scope="scope">
						<div>{{scope.row.createDate}}</div>
					</template>
				</el-table-column>
				<el-table-column label="状态" width="130">
					<template slot-scope="scope">
						<!-- 1-审核中 2-审核不通过 3-审核通过 -->
						<el-tag v-if="scope.row.auditStatus==1" type="success">审核中</el-tag>
						<el-tag v-if="scope.row.auditStatus==2" type="warning">审核不通过</el-tag>
						<el-tag v-if="scope.row.auditStatus==3" type="danger">审核通过</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="300">
					<template slot-scope="scope">
						<button-permissions :datas="'authArticle'">
							<div class="table-button" v-if="scope.row.auditStatus==1" @click="handleDataShow(scope.row,1)">审核</div>
						</button-permissions>
						<button-permissions :datas="'delArticle'">
							<div class="table-button" @click="sureDel(scope.row,2)">删除</div>
						</button-permissions>
						<div class="table-button" v-if="scope.row.auditStatus!=1" @click="handleDataShow(scope.row,2)">查看</div>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination v-if="searchData.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="searchData.pageNo"
				:page-sizes="[10, 20, 30, 40, 50]" :page-size="searchData.size"
				layout="total, sizes, prev, pager, next, jumper" :total="searchData.total"></el-pagination>
		</div>
		<!-- 审核 -->
		<el-dialog v-if="currentRow" title="审核" :visible.sync="auditPopShow" width="1000px">
			<el-form label-width="120px" class="demo-ruleForm">
				<el-form-item label="审核内容:" label-width="150px">
					<div style="background-color: #f5f5f5;width: 700px;min-height: 100px;border-radius: 4px;padding: 10px;">{{currentRow.content||''}}</div>
				</el-form-item>
				<el-form-item label="审核说明:" label-width="150px">
					<el-input :disabled="currentRow.auditStatus!=1" type="textarea" :rows="4" v-model="auditRemark" placeholder="请填写审核说明" style="width:400px"></el-input>
				</el-form-item>
				<div style="text-align: center;margin-top: 30px;" v-if="currentRow.auditStatus==1">
					<el-button style="width: 120px;" @click="auditBefore(2)">审核不通过</el-button>
					<el-button type="primary" style="width: 120px;margin-left:25px;" @click="auditBefore(3)">审核通过</el-button>
				</div>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		auditActivityList,
		auditPromotionActivity,
		deleteAuditActivity,
	} from '@/api/wyUsedInterface.js'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				applyTime: '', //时间
				searchData: {
					beginDate: '', //开始时间
					endDate: '', //结束时间
					total: 0,
					pageNo: 1,
					size: 20,
					auditStatus: null,//审核状态： 1-审核中 2-审核不通过 3-审核通过
					activityId:0,
					nickName:'',//用户昵称
					phoneNumber:'',//用户账号
					pusherLevel:null,//用户等级
					userId:'',//用户id
				},
				loading: false,
				tableData: [],
				// 1-审核中 2-审核不通过 3-审核通过
				activityStateList: [{
						state: null,
						str: '全部'
					},
					{
						state: 1,
						str: '审核中'
					},
					{
						state: 2,
						str: '审核不通过'
					},
					{
						state: 3,
						str: '审核通过'
					},
				],
				levelList:[
					{
						state: null,
						str: '全部'
					},
					{
						state: 0,
						str: '粉丝'
					},
					{
						state: 1,
						str: '1级'
					},
					{
						state: 2,
						str: '2级'
					},
					{
						state: 3,
						str: '3级'
					},
					{
						state: 4,
						str: '4级'
					}
				],
				auditPopShow:false,
				currentRow:null,
				auditRemark:'',
			}
		},
		async beforeMount() {
			this.searchData.activityId = this.$route.query.id;
			this.getList();
		},
		computed: {

		},
		methods: {
			// 获取列表数据
			async getList() {
				this.loading = true
				try {
					if (this.applyTime) {
						this.searchData.beginDate = this.applyTime[0];
						this.searchData.endDate = this.applyTime[1];
					} else {
						this.searchData.beginDate = '';
						this.searchData.endDate = '';
					}
					let data = {
						pageNo: this.searchData.pageNo,
						pageSize: this.searchData.size,
						auditStatus: this.searchData.auditStatus,
						beginDate: this.searchData.beginDate,
						endDate: this.searchData.endDate,
						activityId:this.searchData.activityId,
						nickName:this.searchData.nickName,
						phoneNumber:this.searchData.phoneNumber,
						pusherLevel:this.searchData.pusherLevel,
						userId:this.searchData.userId,
					};
					let result = '';
					result = await auditActivityList(data);
					if (result.data) {
						result.data.records.map(item => {
							//活动状态 0-未开始 1-进行中 2-已结束
							item.stateValue = item.activityStatus == 0 ? '未开始' : (item.activityStatus == 1 ?
								'进行中' : '已结束');
							return item;
						})
						this.tableData = result.data.records;
						this.searchData.total = result.data.total;
					}
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			//搜索
			search() {
				this.searchData.pageNo = 1
				this.searchData.isDownload = false; //下载文件标记
				if (!this.searchData.total) {
					this.searchData.size = 20
				}
				this.getList()
			},
			//跳转至详情
			handleDetailShow(row, type) {
				//1是编辑 0是创建
				let params = {
					id: row.id || null,
					type: type,
					isContinue: (row.id == 0 || (row.id != 0 && row.activityStatus != 1)) ? 0 : 1
				}
				this.publicToDetailsR(params, '/market/luckDraw/drawCreate', this);
			},
			handleDataShow(row,type) {
				if(type==1){
					this.currentRow = row;
					this.auditRemark = '';
					this.auditPopShow = true;
				}else{
					this.currentRow = row;
					this.auditRemark = this.currentRow.auditRemark;
					this.auditPopShow = true;
				}
				
			},
			async auditBefore(type){
				if(type==2&&!this.auditRemark){
					this.$message({
						showClose: true,
						type: 'error',
						message: '请填写否决原因'
					});
					return;
				}
				var tip = type==2?'确认审核不通过吗？':'确认审核通过吗？';
				this.$confirm(tip, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true;
					this.auditPromotion(type);
				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				})
			},
			async auditPromotion(type){
				let data = {
					id: this.currentRow.id,
					auditRemark:this.auditRemark,
					auditStatus:type,
				};
				let res = await auditPromotionActivity(data);
				if (res.code == 200) {
					if (res.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message:type==2 ? "审核已否决":"已通过审核"
						});
						this.auditPopShow = false;
						this.getList();
					} else {
						this.loading = true;
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg
						});
					}
				} else {
					this.loading = true;
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
			},
			async handleFinish(row) {
				let data = {
					id: row.id
				};
				let res = await deleteAuditActivity(data);
				if (res.code == 200) {
					if (res.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message: "删除成功"
						});
						this.getList();
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg
						});
					}
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.searchData.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.searchData.pageNo = val;
				this.getList();
			},
			sureDel(obj) {
				this.$alert('确定删除当前所选数据吗', '温馨提示', {
					confirmButtonText: '确定',
					showCancelButton: true,
					callback: action => {
						if (action == 'confirm') {
							this.handleFinish(obj);
						}
					}
				});
			},
			clearSearch() {
				this.searchData = {
					applyStartTime: '', //开始时间
					applyEndTime: '', //结束时间
					total: 0,
					pageNo: 1,
					size: 20,
					activeType: null
				}
				this.applyTime = '';
				this.search();
			}
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		background-color: #fff;
	}

	.input-item {
		margin-left: 5px;
	}

	.table-button {
		color: #409eff;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}

	/deep/.el-table__fixed-body-wrapper {
		background: #ffffff;
	}

	/deep/.el-table__fixed-header-wrapper {
		background: #f8f8f9;
	}
</style>
